import React, { useEffect, useState, useRef } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import InputGroup from 'react-bootstrap/InputGroup';

import Categories from './Categories';
import Periods from "../Periods";
import { postData } from '../../services/RequestWrapper';


function UserSettings() {
    const [monoToken, setMonoToken] = useState('');

    useEffect(() => {
        
    }, [])

    const handleSaveSettings = () => {
        postData('/api/user/savemonotoken', { token: monoToken }).then(data => {
            setMonoToken('');
            alert('Saved!');
        });
    };

    return (
        <div>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Періоди</Accordion.Header>
                    <Accordion.Body>
                        <Periods />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Категорії</Accordion.Header>
                    <Accordion.Body>
                        <Categories />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Monobank Token</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <InputGroup>
                                <Form.Control placeholder="token" aria-label="token" type="text" value={monoToken} onChange={e => setMonoToken(e.target.value)} />
                                <Button variant="outline-secondary" onClick={handleSaveSettings}>Save</Button>
                            </InputGroup>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
}

export default UserSettings;