import { useEffect, forwardRef, useImperativeHandle, useState } from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { getData, postData, postFormData } from '../../services/RequestWrapper';

const AddEditDialog = forwardRef((props, ref) => {
	const [showModal, setShowModal] = useState(false);
	const [categories, setCategories] = useState([]);

	const [transactionId, setTransactionId] = useState(null);
	const [transactionCategory, setTransactionCategory] = useState(0);
	const [transactionDate, setTransactionDate] = useState((new Date()).toISOString().split('T')[0]);
	const [transactionAmount, setTransactionAmount] = useState(0);	
	const [transactionDescription, setTransactionDescription] = useState('');
	const [transactionComment, setTransactionComment] = useState('');

	const [isNewRecord, setIsNewRecord] = useState(false);
	

	useEffect(() => {
		getData('/api/config/category/get/all').then(data => { setCategories(data); });
	}, [])

	useImperativeHandle(ref, () => ({
		handleShow(transactionId) {
			setIsNewRecord(transactionId == -1);

			if (transactionId == -1) {
				setTransactionId(-1);
				setTransactionCategory(0);
				setTransactionAmount('');
				setTransactionDescription('');
				setTransactionComment('');
				setShowModal(true);
			} else {
				postFormData('/api/transaction/get', { id: transactionId }).then(data => {
					data.amount = - data.amount / 100;
					setTransactionId(data.id);
					setTransactionCategory(data.categoryId);
					setTransactionAmount(data.amount);
					setTransactionDescription(data.description);
					setTransactionComment(data.comment);
					setTransactionDate((new Date(data.time * 1000)).toISOString().split('T')[0]);
					setShowModal(true);
				});
			}
		}
	}));

	const handleClose = () => {
		setShowModal(false);
	}

	const handleSave = () => {
		postData('/api/transaction/edit', { id: transactionId, category: transactionCategory, cost: - transactionAmount * 100, description: transactionDescription, comment: transactionComment, date: ((new Date(transactionDate)).getTime() / 1000) }).then(data => {
			setShowModal(false);
			props.reloadData();
		});
	}

	return (
		<div>
			<Modal show={showModal} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{isNewRecord ? "Додати" : "Редагувати"}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-3">
							<Form.Label>Ціна</Form.Label>
							<Form.Control placeholder="Ціна" type="number" value={transactionAmount} onChange={e => setTransactionAmount(e.target.value) } />
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Категорія</Form.Label>
							<Form.Select value={transactionCategory} onChange={e => setTransactionCategory(e.target.value)}>
								<option key={0} value={0}>Без категорії</option>
								{categories.map((category, index) => (
									<option key={category.id} value={category.id}>{category.name}</option>
								))}
							</Form.Select>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Дата</Form.Label>
							<Form.Control placeholder="Дата" type="date" value={transactionDate} onChange={e => setTransactionDate(e.target.value) } />
						</Form.Group>							
						<Form.Group className="mb-3">
							<Form.Label>Опис</Form.Label>
							<Form.Control placeholder="Опис" type="text" value={transactionDescription} onChange={e => setTransactionDescription(e.target.value)} />
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Коментар</Form.Label>
							<Form.Control placeholder="Коментар" type="text" value={transactionComment} onChange={e => setTransactionComment(e.target.value) } />
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleSave}>
						Зберегти
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
});

export default AddEditDialog;