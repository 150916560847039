function checkRedirectStatus(response) {
    if (response.redirected === true && window.location.pathname !== '/') window.location.href = '/';
}

export function postData(url, data) {
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    }).then(response => {
        checkRedirectStatus(response);
        return response.json();
    }).catch(error => {
        console.error(error);
        throw (error);
    });
}

export function postFormData(url, data) {
    const formData = new FormData();
    for (var prop in data) {
        formData.append(prop, data[prop]);
    }

    return fetch(url, {
        method: 'POST',
        body: formData
    }).then(response => {
        checkRedirectStatus(response);
        return response.json();
    }).catch(error => {
        console.error(error);
        throw (error);
    });
}

export function getData(url) {
    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(response => {
        checkRedirectStatus(response);
        return response.json();
    }).catch(error => {
        console.error(error);
        throw (error);
    });
}