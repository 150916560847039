export const SessionManager = {
    isAuthentificated() {
        // localStorage ?? sessionStorage
        return localStorage.getItem("name") !== null;
    },

    getValue(param) {
        return localStorage.getItem(param);
    },

    setUserSession(name, avatar) {
        localStorage.setItem('name', name);
        localStorage.setItem('avatar', avatar);
    },

    removeUserSession() {
        localStorage.removeItem('name');
        localStorage.removeItem('avatar');
    }
}

export default SessionManager;