import { Home } from "./components/Home";
import AuthForm from './components/AuthForm';
import History from "./components/CashFlow/History";
import Report from "./components/Report/Report";
import Planner from "./components/Planner";
import UserSettings from './components/Settings/UserSettings';
import Periods from "./components/Periods";

const AppRoutes = [
	{
		path: '/login',
		element: <AuthForm />
	},
	{
		index: true,
		element: <Home />
	},
	{
		path: '/history',
		element: <History />
	},
	{
		path: '/report',
		element: <Report />
	},
	{
		path: '/periods',
		element: <Periods />
	},
	{
		path: '/planner',
		element: <Planner />
	},
	{
		path: '/user-settings',
		element: <UserSettings />
	}
];

export default AppRoutes;
