import React, { Component, useState } from 'react';
import AuthForm from './AuthForm';
import Report from './Report/Report';
import CashFlowHistory from './CashFlow/History';
import Planner from "./Planner";
import TransactionsSyncNow from './TransactionsSyncNow';
import UserSettings from './Settings/UserSettings';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

export function Home() {
	const [tab, setTab] = React.useState('home');
	const [isAuthorized, setIsAuthorized] = useState(false);

	const handleOnAuthorized = () => {
		setIsAuthorized(true);
	};

	const handleSelectTab = (key) => {
		setTab(key);
	};
	
	return (
		<div>
			<Tabs defaultActiveKey="home" id="fill-tab-example" className="mb-3" fill onSelect={e => handleSelectTab(e)} >
				<Tab eventKey="home" title="Головна">
					<AuthForm onAuthorized={handleOnAuthorized} />
					{isAuthorized && 
						<div>
							<br />
							<br />
							<TransactionsSyncNow onUpdated={() => { }} />
							<br />
							<UserSettings />
						</div>
					}
				</Tab>
				<Tab eventKey="history" title="Історія" disabled={!isAuthorized}>
					{tab === 'history' &&
						<CashFlowHistory />
					}
				</Tab>
				<Tab eventKey="report" title="Звіт" disabled={!isAuthorized}>
					{tab === 'report' &&
						<Report />
					}
				</Tab>
				<Tab eventKey="plan" title="План" disabled={!isAuthorized}>
					{tab === 'plan' &&
						<Planner />
					}
				</Tab>
			</Tabs>
		</div>
	);
}

export default Home;