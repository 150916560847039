import React, { useEffect, useState, useRef } from 'react';

import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { postFormData, getData } from '../../services/RequestWrapper';
import ChoosePeriod from '../ChoosePeriod';
import SplitDialog from './SplitDialog';
import AddEditDialog from './AddEditDialog';

import './History.css';

function CashFlowHistory() {
	const [selectedPeriod, setSelectedPeriod] = useState(null);

	const [transactions, setTransactions] = useState([]);
	const [categories, setCategories] = useState([]);
	const [selectedFilterCategory, setSelectedFilterCategory] = useState(0);

	const [isHiddenVisible, setIsHiddenVisible] = useState(false);

	const splitDialogRef = useRef(null);
	const addEditDialogRef = useRef(null);

	useEffect(() => {
		getData('/api/config/category/get/all').then(data => { setCategories(data); });
	}, [])

	const reloadData = () => {
		handlePeriodChange(selectedPeriod);
	}

	const handlePeriodChange = (p) => {
		setSelectedPeriod(p);

		postFormData("/api/transaction/get/period", { id: p.id }).then(data => {
			setTransactions(data);
		});
	};

	const handleSetCategory = (index, id, category) => {
		postFormData("/api/transaction/set/category", { id: id, category: category }).then(data => {
			transactions[index] = data;
			const clone = transactions.map(t => t);
			setTransactions(clone);
		});
	}

	const handleAddRule = (index) => () => {
		postFormData("/api/config/rule/addorupdate", { period: selectedPeriod.id, category: transactions[index].categoryId, text: transactions[index].description }).then(data => {
			postFormData("/api/transaction/get/period", { id: selectedPeriod.id }).then(data => {
				setTransactions(data);
				alert('Done!');
			});
		});
	}

	const handleIncomeFilterVisibility = (b) => () => {
		setIsHiddenVisible(b);
	}

	const handleShowSplitDialog = (id) => () => {
		splitDialogRef.current.handleShow(id);
	}

	const handleShowEditDialog = (id) => () => {
		addEditDialogRef.current.handleShow(id);
	}	

	const handleToggleTransactionVisibility = (index) => () => {
		postFormData("/api/transaction/toggle/visibility", { id: transactions[index].id }).then(data => {
			transactions[index] = data;
			const clone = transactions.map(t => t);
			setTransactions(clone);
		});
	};

	return (
		<div>
			<ChoosePeriod onPeriodChanged={handlePeriodChange} />

			<div className="row">
				<div className="col-11">
					<Form.Select className="categoriesFilter" onChange={e => setSelectedFilterCategory(e.target.value)}>
						<option key={0} value={0}>Вся історія</option>
						{categories.map((category, index) => (
							<option key={category.id} value={category.id}>{category.name}</option>
						))}
					</Form.Select>
				</div>
				<div className="col-1">
					<Dropdown className="filer-button">
						<Dropdown.Toggle id="dropdown-basic" className='transparent-button'>
							<i className='bi bi-funnel'></i>
						</Dropdown.Toggle>

						<Dropdown.Menu>
							{isHiddenVisible ?
								(<Dropdown.Item onClick={handleIncomeFilterVisibility(false)} >Приховати приховані</Dropdown.Item>) :
								(<Dropdown.Item onClick={handleIncomeFilterVisibility(true)} >Показати приховані</Dropdown.Item>)
							}
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</div>			

			<Container className="items-list">
				{ /* TODO: Add message if no reccords yet */ }
				{transactions.map((transaction, index) => (
					((isHiddenVisible || !transaction.isHidden) && (selectedFilterCategory == 0 || transaction.categoryId == selectedFilterCategory)) &&
						<Row key={'d' + transaction.id} className="transaction" variant={(transaction.categoryId ?? 0) === 0 ? 'warning' : ''} >
							<Col xs={2} md={1}>									
								<i className={'text-center category-big-icon bi bi-' + categories.find(x => x.id === transaction.categoryId)?.icon}>&nbsp;</i>
							</Col>
							<Col xs={10} md={11}>
								<div className="row">
									<div className="col-11 description">
										{transaction.isHidden &&
											<i className={'hidden-reccord bi bi-eye-slash'}>&nbsp;</i>
										}
										{transaction.description} <i className="comment">{transaction.comment}</i>
									</div>
									<div className="col-1">
										<Dropdown className="list-item-menu-button">
											<Dropdown.Toggle variant="success" id="dropdown-basic"></Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item onClick={handleAddRule(index)}>Створити правило</Dropdown.Item>
												{transaction.amount < 0 &&
													<Dropdown.Item onClick={handleShowSplitDialog(transaction.id)}>Розділити</Dropdown.Item>
												}
												<Dropdown.Item onClick={handleToggleTransactionVisibility(index)}>
													{transaction.isHidden ? "Показати" : "Приховати"}
												</Dropdown.Item>
												<Dropdown.Item onClick={handleShowEditDialog(transaction.id)} >Редагувати</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</div>
								</div>
								<div className="row">
									<div className="col-9">
										<Form.Select value={transaction.categoryId ?? 0} onChange={e => handleSetCategory(index, transaction.id, e.target.value)}>
											<option key={0} value={0}>Не вибрано</option>
											{categories.map((category, index) => (
												<option key={category.id} value={category.id}>{category.name}</option>
											))}
										</Form.Select>
									</div>
									<div className="col-3">
										{transaction.amount / 100}
									</div>
								</div>
								<div className="row">
									<div className="col-9">
										{new Date(transaction.time * 1000).toLocaleDateString(undefined, { month: "numeric", day: "numeric" })}
										&nbsp;
										{new Date(transaction.time * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
													
									</div>
									<div className="col-3">
										{transaction.cashbackAmount > 0 &&
											(<span className="cashback" title="Кешбек"> +{transaction.cashbackAmount / 100}</span>)
										}
										{transaction.commissionRate > 0 &&
											(<span className="commission" title="Комісія"> -{transaction.commissionRate / 100}</span>)
										}
									</div>
								</div>
							</Col>
						</Row>					
				))}
			</Container>

			<Button variant="primary" size="lg" className="newReccordBtn" active onClick={ handleShowEditDialog(-1) }>
				+
			</Button>

			<SplitDialog ref={splitDialogRef} reloadData={reloadData}></SplitDialog>
			<AddEditDialog ref={addEditDialogRef} reloadData={reloadData}></AddEditDialog>
		</div>
	);
}

export default CashFlowHistory;