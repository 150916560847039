import React, { useEffect, forwardRef, useState, useRef } from 'react';

import { GoogleLogin } from '@react-oauth/google';
import { postData } from '../services/RequestWrapper';
import { SessionManager } from '../services/SessionManager';

import './AuthForm.css';

// npm install @react-oauth/google@latest

const AuthForm = forwardRef((props, ref) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [name, setName] = useState(false);
    const [avatar, setAvatar] = useState(false);

    useEffect(() => {
        postData('/api/auth/isauthenticated', null).then(data => {
            if (data.isAuthenticated) {
                setName(SessionManager.getValue('name'));
                setAvatar(SessionManager.getValue('avatar'));
                setIsLoggedIn(true);
                props.onAuthorized();
            } else {
                SessionManager.removeUserSession();
            }
        });
    }, [])

    const handleSuccess = (credentialResponse) => {
        postData('/api/auth/google/login', credentialResponse).then(data => {
            SessionManager.setUserSession(data.name, data.avatar);
            setName(SessionManager.getValue('name'));
            setAvatar(SessionManager.getValue('avatar'));
            setIsLoggedIn(true);
            props.onAuthorized();
        });
    };

    const handleLogout = () => {
        postData('/api/auth/logout', {}).then(data => {
            setIsLoggedIn(false);
            SessionManager.removeUserSession();
        });
    }

    const handleError = () => {
        console.log('Google login failed');
        setIsLoggedIn(false);
        SessionManager.removeUserSession();
    };

    return (
        <div className='auth-form'>
            {!isLoggedIn ?
                (<div className="google-login-button"><GoogleLogin onSuccess={handleSuccess} onError={handleError} useOneTap /></div>) :
                (<div>

                    <img src={avatar} className='avatar' />
                    <div className="links">
                        { // <span className='name'>{name}</span><br/> 
                        }
                        <button onClick={handleLogout}>Logout</button>
                    </div>
                </div>)
            }
        </div>
    );
});

export default AuthForm;