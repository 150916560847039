import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';

import { postData } from './services/RequestWrapper';

export default class App extends Component {
	static displayName = App.name;

	constructor(props) {
		super(props);

		postData('/api/auth/isauthenticated', null).then(data => {
			if (!data.isAuthenticated && window.location.pathname !== '/') window.location.href = '/';
		});
	}

	render() {
		return (
			<Layout>
				<Routes>
					{AppRoutes.map((route, index) => {
						const { element, ...rest } = route;
						return <Route key={index} {...rest} element={element} />;
					})}
				</Routes>
			</Layout>
		);
	}
}
