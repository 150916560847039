import { useEffect, forwardRef, useImperativeHandle, useRef, useState } from 'react';

import Form from 'react-bootstrap/Form';
import { getData } from '../services/RequestWrapper';

const ChoosePeriod = forwardRef((props, ref) => {
    const [selectedPeriod, setSelectedPeriod] = useState(-1);
    const [periods, setPeriods] = useState([]);

    useImperativeHandle(ref, () => ({
        getPreviousPeriodId(selectedPeriodId) {
            var index = periods.findIndex(e => e.id == selectedPeriodId);
            if (index > 0) return periods[index - 1].id;
            return -1;
        }
    }))


    useEffect(() => {
        getData('/api/config/period/get/all').then(data => {
            setPeriods(data);

            // Choose current period
            if (data.length > 0) {
                const dateNow = Date.parse(new Date().toJSON().slice(0, 10));
                for (var i = 0; i < data.length; i++) {
                    if (Date.parse(data[i].startDate) <= dateNow && dateNow < Date.parse(data[i].endDate)) {
                        props.onPeriodChanged(data[i]);
                        setSelectedPeriod(data[i].id);
                        break;
                    }
                }                
            }
        });
    }, [])

    const handleSelectPeriodChange = (e) => {
        setSelectedPeriod(e.target.value);
        props.onPeriodChanged(periods.find(x => x.id == e.target.value));
    };

    return (
        <div className='choose-period'>
            <Form.Select value={selectedPeriod} onChange={handleSelectPeriodChange}>
                {periods.map((period, index) => (
                    <option key={period.id} value={period.id}>{period.description + ": " + period.startDate + " - " + period.endDate}</option>
                ))}
            </Form.Select>

            <br/>
        </div>
    );
});

export default ChoosePeriod;