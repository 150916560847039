import { useEffect, forwardRef, useImperativeHandle, useState } from 'react';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { postData, postFormData } from '../../services/RequestWrapper';

const SplitDialog = forwardRef((props, ref) => {
	const [showModal, setShowModal] = useState(false);
	const [transaction, setTransaction] = useState(null);
	const [descriptionA, setDescriptionA] = useState('');
	const [descriptionB, setDescriptionB] = useState('');
	const [costA, setCostA] = useState('');
	const [costB, setCostB] = useState('');

	useEffect(() => {

	}, [])

	useImperativeHandle(ref, () => ({
		handleShow(transactionId) {
			postFormData('/api/transaction/get', { id: transactionId }).then(data => {
				data.amount = - data.amount / 100;
				setTransaction(data);
				
				setDescriptionA(data.description + ' (1)');
				setDescriptionB(data.description + ' (2)');
				setCostA(data.amount);
				setCostB(0);

				setShowModal(true);
			});
		}
	}));

	const updateCostA = (n) => {
		n = parseInt(n);
		setCostA(n);
		setCostB(Math.round((transaction.amount - n) * 100) / 100);
	}
	const updateCostB = (n) => {
		n = parseInt(n);
		setCostA(Math.round((transaction.amount - n) * 100) / 100);
		setCostB(n);
	}

	const handleClose = () => {
		setShowModal(false);
	}

	const handleSave = () => {
		postData('/api/transaction/split', { id: transaction.id, costA: - costA * 100, costB: - costB * 100, descriptionA: descriptionA, descriptionB: descriptionB }).then(data => {
			setShowModal(false);
			props.reloadData();
		});
	}

	return (
		<div>
			<Modal show={showModal} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Розділити на два рахунки</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group>
							<InputGroup size="sm">
								<Form.Control placeholder="Ціна" type="number" style={{ width: '20%' }} value={costA} onChange={e => updateCostA(e.target.value)} />
								<InputGroup.Text>₴</InputGroup.Text>
								<Form.Control placeholder="Витрата" type="text" style={{ width: '70%' }} value={descriptionA} onChange={e => { setDescriptionA(e.target.value); } } />
							</InputGroup>
						</Form.Group>
						<br/>
						<Form.Group>
							<InputGroup size="sm">
								<Form.Control placeholder="Ціна" type="number" style={{ width: '20%' }} value={costB} onChange={e => updateCostB(e.target.value)} />
								<InputGroup.Text>₴</InputGroup.Text>
								<Form.Control placeholder="Витрата" type="text" style={{ width: '70%' }} value={descriptionB} onChange={e => { setDescriptionB(e.target.value); } } />
							</InputGroup>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleSave}>
						Розділити
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
});

export default SplitDialog;