import React, { useEffect, useState, useRef } from 'react';

import { postData, postFormData, getData } from '../../services/RequestWrapper';
import ChoosePeriod from '../ChoosePeriod';
import ExpensesTable from './ExpensesTable';

function Report() {
	const [selectedPeriod, setSelectedPeriod] = useState(null);

	const expensesTableRef = useRef(null);
	
	useEffect(() => {
	}, [])

	const handlePeriodChange = (p) => {
		setSelectedPeriod(p);
		expensesTableRef.current.handlePeriodChange(p);
	};

	return (
		<div>
			<ChoosePeriod onPeriodChanged={handlePeriodChange} />

			<ExpensesTable ref={expensesTableRef} selectedPeriod={selectedPeriod} />
		</div>
	);
}

export default Report;