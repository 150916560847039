import React, { useEffect, useState, useRef } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import BootstrapIconPicker from "../BootstrapIconPicker";

import { postData, postFormData, getData } from '../../services/RequestWrapper';


function Categories() {
	const [categories, setCategories] = useState([]);
	const [showModal, setShowModal] = useState(false);

	const [addCategoryName, setAddCategoryName] = useState('');
	const [addIcon, setAddIcon] = useState('check-circle');	

	const [editIndex, setEditIndex] = useState('');
	const [editCategoryName, setEditCategoryName] = useState('');
	const [editIcon, setEditIcon] = useState('');

	useEffect(() => {
		getData('/api/config/category/get/all').then(data => {
			setCategories(data);
		});
	}, [])

	const handleNewCategory = (e) => {
		postFormData("/api/config/category/add", { icon: addIcon, name: addCategoryName }).then(data => {
			categories.push(data);
			const nc = categories.map(c => c);
			setCategories(nc);

			setAddCategoryName('');
			setAddIcon('check-circle');
		});
	};

	const handleEdit = (index) => () => {
		setShowModal(true);
		setEditIndex(index);
		setEditIcon(categories[index].icon);
		setEditCategoryName(categories[index].name);
	};

	const handleSave = () => {
		postData("/api/config/category/edit", { id: categories[editIndex].id, icon: editIcon, name: editCategoryName }).then(data => {
			categories[editIndex] = data;
			const nc = categories.map(c => c);
			setCategories(nc);

			setEditCategoryName('');
			setEditIcon('');
			setShowModal(false);
		});
	};

	const handleDelete = () => {
		const confirm = window.confirm("Видалити " + editCategoryName + "?");
		if (!confirm) return;

		postFormData("/api/config/category/delete", { id: categories[editIndex].id }).then(data => {
			categories.splice(editIndex, 1);
			const nc = categories.map(c => c);
			setCategories(nc);

			setEditCategoryName('');
			setShowModal(false);
		});
	};

	const handleClose = () => {
		setShowModal(false);
	}

	// TODO: Show all rules, edit, delete

	return (
		<div>
			<Table striped bordered hover>
				<thead>
					<tr>
						<th></th>
						<th>Категорія</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{categories.map((category, index) => (
						<tr key={'c' + category.id}>
							<td></td>
							<td><i className={'bi bi-' + category.icon}></i> {category.name}</td>
							<td><Button variant="outline-secondary" onClick={handleEdit(index)}>Edit</Button></td>
						</tr>
					))}
				</tbody>
			</Table>

			<br/>

			<div>
				<InputGroup>					
					<InputGroup.Text><i className={addIcon}></i><BootstrapIconPicker onChange={(e) => setAddIcon(e)} /></InputGroup.Text>
					<Form.Control placeholder="New Category" aria-label="New Category" type="text" value={addCategoryName} onChange={e => setAddCategoryName(e.target.value)} />
					<Button variant="outline-secondary" onClick={handleNewCategory}>Add</Button>
				</InputGroup>
			</div>

			<Modal show={showModal} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Edit</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-3">
							<Form.Label>Назва</Form.Label>
							<Form.Control placeholder="Назва" aria-label="Назва" type="text" value={editCategoryName} onChange={e => setEditCategoryName(e.target.value)} />
						</Form.Group>
						<Form.Group className="mb-3">
							<BootstrapIconPicker initialIcon={editIcon} onChange={(e) => setEditIcon(e)} />
						</Form.Group>
					</Form>

				</Modal.Body>
				<Modal.Footer>
					<Button variant="danger" onClick={handleDelete}>
						Delete
					</Button>
					<Button variant="primary" onClick={handleSave}>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default Categories;