import React, { useEffect, forwardRef, useState } from 'react';

import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

import { getData, postData } from '../services/RequestWrapper';


const TransactionsSyncNow = forwardRef((props, ref) => {
    const [isSynchronizingNow, setIsSynchronizingNow] = useState(false);
    const [lastFetchTime, setLastFetchTime] = useState(null);

    useEffect(() => {
        getLastFetchTime();
    }, [])

    const getLastFetchTime = () => {
        getData('/api/user/get/lastfetchtime').then(ticks => {
            const date = new Date(ticks / 10000 + new Date('0001-01-01T00:00:00Z').getTime());
            setLastFetchTime(date.toLocaleDateString(undefined, { month: "numeric", day: "numeric" }) + ' ' + date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
        });
    };

    const handleSyncNow = () => {
        setIsSynchronizingNow(true);
        postData('/api/config/history/fetch/now', {}).then(data => {
            getLastFetchTime();
            setIsSynchronizingNow(false);
            props.onUpdated();
        });
    };

    return (
        <div>
            <InputGroup>
                <InputGroup.Text>
                    Оновлено {lastFetchTime}
                </InputGroup.Text>
                <Button disabled={isSynchronizingNow} onClick={handleSyncNow}>синхронізувати</Button>
            </InputGroup>
        </div>
    );
});

export default TransactionsSyncNow;